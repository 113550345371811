import React from 'react';
import style from './TxnCard.module.css';

const TxnCard = (props) => {
	return (
		<div>
			<span>IMG</span>
			<span>ASSETX AMT</span>
			<span>ASSET X VALUE</span>
		</div>
	)
}

export default TxnCard